import { useState, useMemo } from 'react';


import List from "@mui/material/List";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Opportunity from "./components/Opportunity";
import Notification from "./components/Notification";

import { BrowserRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import './App.css';

const App = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const isMobile = useMemo(() => window.innerWidth < 600, []);
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <div>
            {!isMobile ?
              <>
                <div className='head-logo'>
                  <img width="200px" src="/logo.jpeg" />
                  <img width="200px" src="/logo-1.jpeg" />
                </div>
                <main class="container-menu">

                  <nav class="nav">
                    <ul>
                      <li><a href="/">Home</a></li>
                      <li><a href="/about-us">About us</a></li>
                      <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSc9P-WxcompqGHQcnRkDFfcTsh2Ctxhe_uooZijXQ7VbiVjdw/viewform">Register to get support</a></li>
                      <li><a href="/upcoming">Upcoming / Past sessions</a></li>
                      <li><a href="/opportunity">Learning / Job opportunities</a></li>
                    </ul>
                  </nav>

                </main>
                <br />
              </> :
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "10px 15px 10px",
                    position: "fixed",
                    top: 0,
                    background: "#fff",
                    zIndex: 1200,
                    width: "100%",
                  }}
                >
                  <IconButton aria-label="menu" onClick={() => setOpenDrawer(true)}>
                    <MenuIcon />
                  </IconButton>
                  <img width="50%" style={{ marginLeft: 20 }} src="/logo-1.jpeg" />
                </div>
                <div style={{ height: 68 }} />
              </div>
            }

            <SwipeableDrawer
              open={openDrawer}
              className="main-drawer"
              style={{ height: "100%" }}
              onClose={() => setOpenDrawer(false)}
              onOpen={() => setOpenDrawer(true)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={() => setOpenDrawer(false)}
                onKeyDown={() => setOpenDrawer(false)}
              >
                <List>
                  <Link to={"/"}>
                    <ListItem key={"Dashboard"} disablePadding>
                      <ListItemButton>
                        <ListItemText primary={"Home"} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to={"/about-us"}>
                    <ListItem key={"about"} disablePadding>
                      <ListItemButton>
                        <ListItemText primary={"About us"} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to={"https://docs.google.com/forms/d/e/1FAIpQLSc9P-WxcompqGHQcnRkDFfcTsh2Ctxhe_uooZijXQ7VbiVjdw/viewform"}>
                    <ListItem key={"about-us"} disablePadding>
                      <ListItemButton>
                        <ListItemText primary={"Register to get support"} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to={"/upcoming"}>
                    <ListItem key={"Orders"} disablePadding>
                      <ListItemButton>
                        <ListItemText primary={"Upcoming / Past sessions"} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link to={"/opportunity"}>
                    <ListItem key={"opportunity"} disablePadding>
                      <ListItemButton>
                        <ListItemText primary={"Learning / Job opportunity"} />
                      </ListItemButton>
                    </ListItem>
                  </Link>

                </List>
              </Box>
            </SwipeableDrawer>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/opportunity" element={<Opportunity />} />
              <Route path="/upcoming" element={<Notification />} />
            </Routes>
          </div>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
