// import { useState, useEffect, useMemo } from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { SocialIcon } from 'react-social-icons'
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


function Home() {




  const itemData = [
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
  ];


  return (
    <>

      <div className="main-1">
        <img
          src={`/img/about.jpeg`}
          alt={"about"}
          loading="lazy"
        />
        <a href="https://wa.me/+919289030894"><img
          src={`/img/mobile-banner.jpeg`}
          alt={"mobile-banne"}
          loading="lazy"
        />
        </a>
      </div>
      <h3>Find us on</h3>
      <div className='social-icons'>
        <SocialIcon url="https://whatsapp.com/channel/0029Vap5W581iUxUbrz70E1e" />
        <SocialIcon url="https://www.instagram.com/alohomora_education/?hl=en" />
        <SocialIcon url="http://www.youtube.com/@AlohomoraEducationCareerShala" />
        <SocialIcon url="http://twitter.com/alohomora_edu" />
        <SocialIcon url="https://www.linkedin.com/company/alohomora-education/?viewAsMember=true" />
      </div>


      {/* <ImageList sx={{ width: '90%', margin: '0 auto' }}> */}
      {/* <Carousel centerMode dynamicHeight={true} showThumbs={false}>
        {itemData.map((item, index) => (
          <div>
            <img src={`img/${index + 1}.jpeg`} />
          </div>
        ))}
      </Carousel> */}
      {/* </ImageList> */}

      <br />
      <br />
    </>
  );
}

export default Home;
