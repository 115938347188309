import { useState, useEffect, useMemo } from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';



function Notification() {

  const [notification, setNotification] = useState([]);


  const isMobile = useMemo(() => window.innerWidth < 600, []);



  useEffect(() => {

    fetch(
      `https://afs.vyomify.com/apis/fetchNotifications`,
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) setNotification(data)
      });
  }, []);

  return (
    <>
      
        <div>
        <h3>Upcoming Events:</h3>
        <br />
        <div style={{margin: '0 auto', padding: 10 }}>
        {notification.map((not) => (
          <div style={{ border: '1px solid #efefef', borderRadius: '10px', margin: '15px', maxWidth: isMobile ? 500 : 800, margin: '10px auto' }}>

            <CardContent style={{ display: isMobile ? '' : 'flex', justifyContent: 'space-around'}}>
              <img style={{ width: isMobile ? '100%' : '50%', flex: 1 }} src={`data:image/png;base64,${not.posterImage}`} />
              <p style={{ flex: 1, padding: 20 }}>{not.text}</p>
            </CardContent>
            <CardActions style={{ padding: '20px'}}>
              <a href={not.registerLink} size="small">Learn More</a>
              <a href={not.meetingLink} size="small">Join Meet</a>
            </CardActions>
          </div >
        ))}
        </div>
      </div>
   
     
      <br />
        <br />
    </>
  );
}

export default Notification;
