import { useState, useEffect, useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader



function AboutUs() {

  const itemData = [
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
    {
      main: '',
      text: '',
    },
  ];

  return (
    <>

      <div>
        <h3>About Us</h3>
        <br />
        <div className='aboutus'>
          <p>करियरशाला आफ्टर स्कूल सपोर्ट टीम विद्यार्थियों को कॉलेज एडमिशन, स्किलिंग संस्थान, स्कॉलरशिप्स, इंटर्नशिप्स, और विभिन्न क्षमता-विकास क्षेत्रों में सहयोग प्रदान करती है। इसके लिए हर सीनियर सेकेंडरी विद्यालय को यह सुनिश्चित करना है कि:

            करियरशाला के अंत में विद्यार्थी विभिन्न करियर विकल्पों की जानकारी लेकर अपनी स्किल्स और रुचि को ध्यान में रखते हुए करियर का चुनाव करें। उनकी इस करियर चॉइस को एक वास्तविक करियर में बदलने के लिए उन्हें विभिन्न लर्निंग/स्किलिंग कोर्सेज की आवश्यकता होती है। विद्यार्थियों की इस लर्निंग और स्किलिंग आवश्यकता को पूरा करने के लिए करियरशाला आफ्टर स्कूल सपोर्ट टीम विभिन्न इंस्टीट्यूट्स, ऑर्गनाइजेशन, कॉलेज और यूनिवर्सिटीज की रिसर्च करती है और विद्यार्थियों को उनके साथ जोड़ती है।

            करियरशाला आफ्टर स्कूल एलुमनाई सपोर्ट टीम इस उद्देश्य को पूरा करने के लिए निम्नलिखित कार्य करती है:
            <ul>
              <li>विभिन्न स्किलिंग और लर्निंग अवसरों की रिसर्च कर सेक्टर-वाइज सूची तैयार करना।</li>
              <li>स्थानीय अवसरों की रिसर्च करना और उनकी जानकारी विद्यार्थियों तक पहुँचाना।</li>
              <li>विभिन्न सेक्टर्स के कोर्सेज का ओरिएंटेशन सेशन आयोजित करना और विद्यार्थियों को एडमिशन प्रोसेस की जानकारी व सहयोग प्रदान करना।</li>
              <li>विद्यार्थियों के लिए कैपेसिटी बिल्डिंग सेशन आयोजित करना ताकि वे जॉब-रेडी बन सकें।</li>
              <li>विद्यार्थियों से कॉल और व्हाट्सएप के माध्यम से उनके करियर संबंधी समस्याओं पर चर्चा करना।</li>
              <li>सेल्फ-एम्प्लॉयमेंट में रुचि रखने वाले विद्यार्थियों की पहचान कर उन्हें सहयोग प्रदान करना।</li>
              <li>सरकार द्वारा चुने गए सेक्टर्स में अवसरों की जानकारी विद्यार्थियों तक पहुँचाना और संबंधित सरकारी स्कीम्स की जानकारी प्रदान करना।</li>

            </ul>






          </p>
        </div>
        <Carousel centerMode dynamicHeight={true} showThumbs={false}>
          {itemData.map((item, index) => (
            <div>
              <img src={`img/${index + 1}.jpeg`} />
            </div>
          ))}
        </Carousel>

      </div>


      <br />
      <br />
    </>
  );
}

export default AboutUs;
