import React, { useState, useEffect } from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Checkbox from '@mui/material/Checkbox';
import { SocialIcon } from 'react-social-icons'

function Opportunity() {
  const [drawer, setDrawer] = useState(false);
  const [opp, setOpp] = useState([]);
  const [filter, setFilter] = useState({});

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCourse, setSelectedCourse] = useState([]);

  useEffect(() => {

    fetch(`https://afs.vyomify.com/apis/fetchOpportunity`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        courses: selectedCourse,
      }),
    }).then((res) => res.json())
      .then((data) => {
        setOpp(data);
        const filterData = data.reduce((acc, curr) => {
          acc = { ...acc, [curr.sector]: (acc?.[curr.sector] || []).concat([curr.course]) }
          return acc;
        }, {});
        if (!selectedCourse.length) {
          setFilter(filterData);
          setSelectedCategory(Object.keys(filterData)?.[0] || '');
        }
      });
  }, [selectedCourse]);


  return (
    <div style={{ maxWidth: 500, margin: '0 auto', padding: '10px' }}>
      <h4>Total: {`${opp.length}`}</h4>
      {opp.map((o) => (
        <div className='card-main'>
          <CardContent>
            <p><span className='sector'>{`${o.sector}`}</span>-<span className='course'>{`${o.course}`}</span></p>
            {Boolean(o.institute) && <p>Institute Name : <span className='inst'>{`${o.institute}`}</span></p>}
            <p>Description : </p>
            <p className='desc'>{`${o.description}`}</p>
            <p className="lc">Location : {`${o.location}`}</p>
            <p className="start">{`Starting from ${o.startingFrom}`}</p>
          </CardContent>
          <CardActions style={{ padding: '10px 20px' }}>
            <p>{o.applyLink}</p>
            <div className='social-icons oncard'>
              <SocialIcon url="https://wa.me/+919289030894" />
            </div>
          </CardActions>
        </div>

      ))}
      <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, margin: '10px 0' }}>
        <Button variant="contained" size="medium" onClick={() => setDrawer(true)}>Filter</Button>
      </div>
      <br />
      <br />
      <Drawer
        anchor={'bottom'}
        open={drawer}
        onClose={() => setDrawer(false)}
        style={{ maxWidth: 500 }}
      >
        <>
          <div className='close-icon' onClick={() => setDrawer(false)}></div>
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <div style={{ flex: 2, background: '#e8e4e4', padding: '5px' }}>
              {(Object.keys(filter) || []).map(f => (<p><Button variant={`${f === selectedCategory ? "contained" : ''}`} size="medium" onClick={() => setSelectedCategory(f)}>{f}</Button></p>))}
            </div>
            <div style={{ flex: 4, padding: '5px' }}>
              {(filter?.[selectedCategory] || []).map(c => (
                <p><Checkbox label="Nursing" size="small" checked={selectedCourse.includes(c)} onChange={() => {
                  const courses = [...selectedCourse];
                  const index = courses.indexOf(c);
                  if (index > -1) {
                    courses.splice(index, 1);
                  } else courses.push(c);
                  setSelectedCourse(courses);
                }} />{c}</p>
              ))}
            </div>
          </div>
        </>
      </Drawer>
    </div>
  );
}

export default Opportunity;
